<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label>Voucher Type</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                name="Voucher Type"
                rules="required"
              >
                <v-select
                  v-model="vouchertype"
                  placeholder="None"
                  :disabled="userInfo && userInfo.role !== 'admin'"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="['Advance', 'Bill To Bill', 'On Account']"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Receipt Date </label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                name="Receipt Date"
                rules="required"
              >
                <flat-pickr
                  v-model="receiptdate"
                  class="form-control"
                  :config="{
                    dateFormat: 'd/m/Y',
                    defaultDate: new Date(),
                    maxDate: new Date(),
                  }"
                  style="background-color: transparent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Transaction type</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                name="TransactionType"
                rules="required"
              >
                <v-select
                  v-model="transactiontype"
                  placeholder="None"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="[
                    'By Cheque',
                    'By RTGS',
                    'By NEFT',
                    'By ECS',
                    'Debit/Credit Card',
                    'Net Banking',
                    'By UPI',
                    'Other',
                  ]"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Company Name</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" name="Company" rules="required">
                <v-select
                  v-model="companyname"
                  placeholder="None"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="companyOption"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Client Name</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                name="Client Name"
                rules="required"
              >
                <v-select
                  v-model="clientname"
                  placeholder="None"
                  label="fullname"
                  :options="customerOption"
                  @input="handleCustomer($event)"
                >
                  <template #option="{ name, profile_image, surname }">
                    <b-avatar :src="getprofileImage(profile_image)" />
                    <span> {{ name }} {{ surname }} </span>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="clientname">
            <b-form-group>
              <label>Select Project</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" name="Project" rules="required">
                <v-select
                  label="projectname"
                  v-model="project"
                  placeholder="None"
                  :options="projectOptions"
                  @input="getSalesDetails($event)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Ledger Name</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" name="Ledger" rules="required">
                <v-select
                  v-model="ledgername"
                  placeholder="None"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="bankledgerOption"
                >
                  <template #option="{ name, accountno }">
                    <span> {{ name }} {{ accountno ? " / " + accountno : "" }} </span>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Transaction Date </label>
              <flat-pickr
                v-model="transaction_date"
                class="form-control"
                :config="{
                  dateFormat: 'd/m/Y',
                }"
                style="background-color: transparent"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="transactiontype !== 'Other'">
            <b-form-group>
              <label>Client Bank Name</label>
              <b-form-input v-model="bankname" placeholder="Enter Bank Name" />
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="transactiontype !== 'Other'">
            <b-form-group>
              <label>Branch Name</label>
              <b-form-input v-model="branchname" placeholder="Enter Branch Name" />
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="transactiontype == 'By Cheque'">
            <b-form-group>
              <label>Cheque No</label>
              <b-form-input v-model="chequeno" placeholder="Enter Cheque No" />
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="transactiontype == 'By Cheque'">
            <b-form-group>
              <label>Cheque Date </label>
              <flat-pickr
                v-model="chequedate"
                class="form-control"
                :config="{
                  dateFormat: 'd/m/Y',
                  defaultDate: new Date(),
                }"
                style="background-color: transparent"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="transactiontype !== 'Other'">
            <b-form-group>
              <label>UTR No (Ref. No.)</label>
              <b-form-input v-model="utrno" placeholder="Enter UTR No" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Remarks</label>
              <b-form-input v-model="remarks" placeholder="Enter Remarks" />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Attachment 1</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                name="Attachment 1"
                rules="required"
              >
                <b-form-file
                  v-model="documents.attachment1"
                  placeholder="Select Attachment 1"
                  drop-placeholder="Drop file here..."
                  accept="image/*"
                  @input="handleFileChange($event, 'attachment1', 'receiptreport')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <attachment :data="documents.attachment1" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Attachment 2</label>
              <b-form-file
                v-model="documents.attachment2"
                placeholder="Select Attachment 2"
                drop-placeholder="Drop file here..."
                accept="image/*"
                @input="handleFileChange($event, 'attachment2', 'receiptreport')"
              />
            </b-form-group>
            <attachment :data="documents.attachment2" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Attachment 3</label>
              <b-form-file
                v-model="documents.attachment3"
                placeholder="Select Attachment 3"
                drop-placeholder="Drop file here..."
                accept="image/*"
                @input="handleFileChange($event, 'attachment3', 'receiptreport')"
              />
            </b-form-group>
            <attachment :data="documents.attachment3" />
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Total Amount</label>
              <b-form-input
                v-model="totalamount"
                placeholder="Enter Total Amount"
                type="number"
                @input="handleTotalAmount()"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-card-code title="Receipt details" no-body>
              <b-col></b-col>
            </b-card-code>
          </b-col>
          <b-col md="12">
            <div style="width: 100%; overflow: auto">
              <table class="table table-bordered" style="min-width: 40cm">
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th style="width: 7cm">Sales Date</th>
                    <th style="width: 5cm">Plot No</th>
                    <th style="width: 5cm">offer</th>
                    <th style="width: 7cm">Total Plot Value</th>
                    <th style="width: 7cm">Receipt Amount</th>
                    <th style="width: 7cm">Remaining Amount</th>
                    <th style="width: 7cm">Amount</th>
                    <th style="width: 7cm">Kasar</th>
                    <th style="width: 7cm">Remaining</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in receiptDetails"
                    :key="index"
                    v-if="item.remaining_amount !== '0.00' || ifEdit"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>
                      <flat-pickr
                        v-model="item.sales_date"
                        disabled
                        class="form-control"
                        :config="{
                          dateFormat: 'd/m/Y',
                          defaultDate: new Date(),
                          maxDate: new Date(),
                        }"
                        style="background-color: transparent"
                      />
                    </td>
                    <td>
                      <validation-provider
                        #default="{ errors }"
                        name="Plot No."
                        rules="required"
                      >
                        <b-form-input v-model="item.plotNo" disabled />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </td>
                    <td>
                      {{ item.offer ? item.offer.name : "" }}
                    </td>
                    <td>
                      <validation-provider
                        #default="{ errors }"
                        name="Booking No."
                        rules="required"
                      >
                        <b-form-input v-model="item.total_plot_value" disabled />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </td>

                    <td>
                      <h5>
                        {{ item.total_plot_value - item.remaining_amount }}
                      </h5>
                    </td>
                    <td>
                      <b-form-input
                        disabled
                        v-model="item.remaining_amount"
                        type="number"
                        placeholder="Enter Amount"
                      />
                    </td>
                    <td>
                      <div class="d-flex justify-content-center">
                        <b-form-input
                          @input="calreceipt(index)"
                          v-model="item.amount"
                          :disabled="item.remaining_amount == 0"
                          type="number"
                          placeholder="Enter Amount"
                        />
                      </div>
                    </td>
                    <td>
                      <b-form-input
                        @input="calreceipt(index)"
                        :disabled="item.remaining_amount == 0"
                        v-model="item.kasar"
                        type="number"
                        placeholder="Enter Amount"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.remaining"
                        disabled
                        type="number"
                        placeholder="Enter Due Amount"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
          <b-col class="mt-2" md="8"> </b-col>
          <b-col cols="12" class="mt-1">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitForm"
              :disabled="flag || submitDisable"
            >
              Submit
            </b-button>

            <b-button class="ml-1" variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code";
import {
  BFormCheckbox,
  BFormInput,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BTable,
  BAvatar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from "@/components/axios";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import Attachment from "../../../components/Attechment.vue";
import { CloudLightningIcon } from "vue-feather-icons";

export default {
  components: {
    BFormCheckbox,
    flatPickr,
    Datepicker,
    BFormFile,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BTable,
    Attachment,
    BAvatar,
  },
  data() {
    return {
      bankledgerOption: [],
      documents: {
        attachment1: "",
        attachment2: "",
        attachment3: "",
      },
      salesdate: "",
      receiptDetails: [],
      salesno: "",
      dueamount: "",
      include: "",
      amount: "",
      remaining: "",
      vouchertype: "Bill To Bill",
      project: "",
      plot: "",
      receiptdate: moment(new Date()).format("DD/MM/yyyy"),
      transactiontype: "",
      companyname: "",
      clientname: "",
      bankcash: "",
      ledgername: "",
      accountno: "",
      ifsccode: "",
      ifsccodeValidate: true,
      bankname: "",
      branchname: "",
      chequeno: "",
      utrno: "",
      chequedate: null,
      transaction_date: null,
      remarks: "",
      totalamount: "",
      userInfo: JSON.parse(localStorage.getItem("userData")),
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      ifEdit: false,
      companyOption: [],
      ledgernameOption: [],
      plotOptions: [],
      projectOptions: [],
      id: "",
      flag: false,
      submitDisable: false,
      sales_id: "",
      bookingOptions: [],
      customerOption: [],
      projects: [],
      entireData: [],
      plots: [],
      extraCustomers: [
        {
          customer: "",
          email: "",
          mobile: "",
          birthdate: "",
          panno: "",
          aadharcardno: "",
          nomineename: "",
          nomineerelation: "",
          nomineephone: "",
          nomineedob: "",
        },
      ],
    };
  },
  async mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Receipt") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/accounting/receipt");
            }
          } else if (item.add !== 1) {
            this.$router.push("/accounting/receipt");
          }
        }
      });
    }
    this.init();
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    getprofileImage(image) {
      if (image !== undefined && image !== null && image !== "") {
        let test = image.includes(this.imagePath) ? image : this.imagePath + image;
        return test;
      }
      return image;
    },
    handleExtraCustomer(e, item) {
      if (e) {
        let array = this.extraCustomers.filter((item) => item.customer.id == e.id);
        if (this.clientname && this.clientname.id !== e.id && array.length == 1) {
          item.email = e.email;
          item.mobile = e.mobile;
          item.birthdate = e.birthdate;
          item.panno = e.panno;
          item.aadharcardno = e.aadharcardno;
          item.nomineename = e.nomineename;
          item.nomineerelation = e.nomineerelation;
          item.nomineephone = e.nomineephone;
          item.nomineedob = e.nomineedob;
        } else {
          item.customer = null;
          item.email = "";
          item.mobile = "";
          item.birthdate = "";
          item.panno = "";
          item.aadharcardno = "";
          item.nomineename = "";
          item.nomineerelation = "";
          item.nomineephone = "";
          item.nomineedob = "";
        }
      } else {
        item.customer = null;
        item.email = "";
        item.mobile = "";
        item.birthdate = "";
        item.panno = "";
        item.aadharcardno = "";
        item.nomineename = "";
        item.nomineerelation = "";
        item.nomineephone = "";
        item.nomineedob = "";
      }
    },
    handelAllAmount(e, item, index) {
      if (e) {
        item.amount = parseFloat(item.remaining_amount);
        this.calreceipt();
      }
      this.$forceUpdate(index);
    },
    handleCustomer(e) {
      this.handleProject();
      if (e && this.project) {
        this.getSalesDetails(this.project);
      }
    },
    calreceipt(index) {
      if (
        parseFloat(this.receiptDetails[index].amount) >
        parseFloat(this.receiptDetails[index].remaining_amount)
      ) {
        this.receiptDetails[index].amount = this.receiptDetails[index].remaining_amount;
      }
      this.receiptDetails[index].remaining =
        this.receiptDetails[index].remaining_amount -
        this.receiptDetails[index].amount -
        this.receiptDetails[index].kasar;
      let amount = 0;
      this.receiptDetails.map((item) => {
        amount = amount + parseFloat(item.amount ? item.amount : 0);
      });
      this.totalamount = parseFloat(amount.toFixed(2));
      this.$forceUpdate();
    },
    handleTotalAmount() {
      let amount = parseFloat((this.totalamount / this.receiptDetails.length).toFixed(2));
      let total = 0;
      this.receiptDetails.map((item) => {
        if (amount <= item.remaining_amount) {
          item.amount = amount;
          item.remaining = parseFloat(item.remaining_amount) - parseFloat(item.amount);
        } else {
          item.amount = parseFloat(item.remaining_amount);
        }
        total += parseFloat(item.remaining_amount);
      });
      if (parseFloat(this.totalamount) > total) {
        this.totalamount = total.toFixed(2);
      }
    },
    async getSalesDetails(e, array) {
      if (e) {
        let bank_ids = e.bank_id && e.bank_id ? e.bank_id.split(",") : [];
        this.bankledgerOption = this.ledgernameOption.filter(
          (item) => bank_ids.indexOf(item.id.toString()) !== -1
        );
        let data = {
          client_id: this.clientname.id,
          project_id: e.id,
        };
        await axios({
          method: "POST",
          url: `${this.baseApi}/salesbyCustomer`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          data: data,
        })
          .then((json) => {
            this.entireData = json.data.data;
            this.receiptDetails = [];
            this.entireData.map((item) => {
              let data = {
                receipt_id: "",
                sales_id: item.id,
                sales_date: moment(item.bookingdate, "YYYY-MM-DD").format("DD/MM/YYYY"),
                plotNo: item.plotno.plotno,
                plot_id: item.plotno.id,
                total_plot_value: item.totalpayment,
                remaining_amount: item.remainingpayment,
                amount: 0,
                kasar: 0,
                remaining: item.remainingpayment,
                offer: item.offer,
              };
              if (array && array.length > 0) {
                array.map((inner) => {
                  if (item.id == inner.sales_id) {
                    data.remaining_amount =
                      parseFloat(item.receipt_dueamount) + parseFloat(inner.amount);
                    data.amount = inner.amount;
                    data.kasar = inner.kasar;
                    data.remaining = parseFloat(item.receipt_dueamount);
                  }
                });
              }
              this.receiptDetails.push(data);
            });
          })
          .catch((error) => console.log(error, "error"));
      }
    },
    handleAmount() {
      this.remaining = (this.dueamount - this.amount).toFixed(2);
    },
    async getEditValue() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getreceiptById/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      if (item[0].amount) {
        this.include = true;
      }
      item.map((item) => {
        this.sales_id = item.sales_id;
        this.vouchertype = item.vouchertype;
        this.salesdate = item.salesdate;
        this.salesno = item.salesno;
        this.dueamount = item.remainingamount;
        this.amount = item.amount;
        this.remaining = item.remainingamount;
        this.receiptdate =
          item.receiptdate == "00/00/0000"
            ? null
            : moment(item.receiptdate).format("DD/MM/yyyy");
        this.transactiontype = item.transactiontype;
        this.clientname = item.clientname ? item.clientname : {};
        this.transaction_date =
          item.transaction_date == "00/00/0000"
            ? null
            : moment(item.transaction_date).format("DD/MM/yyyy");
        this.companyname = item.companyname ? item.companyname : {};

        this.bankcash = item.bankcash;
        this.ledgername = item.ledgername ? item.ledgername : {};
        this.accountno = item.accountno;
        this.ifsccode = item.ifsccode;
        this.bankname = item.bankname;
        this.chequeno = item.chequeno;

        this.branchname = item.branchname;
        this.utrno = item.utrno;
        this.chequedate =
          item.chequedate == "00/00/0000"
            ? null
            : moment(item.chequedate).format("DD/MM/yyyy");
        this.remarks = item.remarks;
        this.totalamount = item.amount;
        // this.project = this.handleProject(item.project);
        // this.plot = this.getPlot(item.project,item.plot);
        this.documents.attachment1 = item.attachment1;
        this.documents.attachment2 = item.attachment2;
        this.documents.attachment3 = item.attachment3;
        this.project = item.project ? item.project : {};
        this.getSalesDetails(this.project, item.receipt_items);
      });
      if (this.include == 1) {
        this.include = true;
      }
      if (this.include == 0) {
        this.include = false;
      }

      this.extraCustomers.map((item2) => {
        item2.email = item2.customer.email;
        item2.mobile = item2.customer.mobile;
        item2.birthdate = item2.customer.birthdate;
        item2.panno = item2.customer.panno;
        item2.aadharcardno = item2.customer.aadharcardno;
        item2.nomineename = item2.customer.nomineename;
        item2.nomineerelation = item2.customer.nomineerelation;
        item2.nomineephone = item2.customer.nomineephone;
        item2.nomineedob = item2.customer.nomineedob;
      });
      this.handleProject();
    },
    validateIFSCCodeClick(e) {
      const regex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);
      this.ifsccode = this.ifsccode.toUpperCase();
      if (regex.test(this.ifsccode) == true) {
        this.ifsccodeValidate = true;
      } else {
        this.ifsccodeValidate = false;
      }
    },
    submitForm(e) {
      if (this.include == true) {
        this.include = 1;
      }
      if (this.include == false) {
        this.include = 0;
      }
      let receiptItems = [];
      this.receiptDetails.map((item) => {
        if (item.amount != 0) {
          receiptItems.push(item);
        }
      });
      this.$refs.simpleRules.validate().then(async (success) => {
        let data;
        if (success) {
          let extraCustomers = this.extraCustomers.filter((item) => item.customer);
          extraCustomers = extraCustomers.map((item) => item.customer.id);
          data = {
            receiptDetails: receiptItems,
            sales_id: this.sales_id,
            salesdate: this.salesdate,
            salesno: this.salesno,
            amount: this.include == 1 ? this.amount : this.dueamount,
            vouchertype: this.vouchertype,
            receiptdate: moment(this.receiptdate, "DD/MM/yyyy").format("YYYY-MM-DD"),
            transactiontype: this.transactiontype,
            companyname: this.companyname.id,
            clientname: this.clientname.id,
            bankcash: this.bankcash,
            ledgername: this.ledgername.id,
            accountno: this.accountno,
            ifsccode: this.ifsccode,
            bankname: this.bankname,
            branchname: this.branchname,
            chequeno: this.chequeno,
            utrno: this.utrno,
            chequedate: moment(this.chequedate, "DD/MM/yyyy").format("YYYY-MM-DD"),
            transaction_date: moment(this.transaction_date, "DD/MM/yyyy").format(
              "YYYY-MM-DD"
            ),
            remarks: this.remarks,
            totalamount: this.totalamount,
            project: this.project.id ? this.project.id : "",
            // plot: this.plot.id,
            attachment1: this.documents.attachment1,
            attachment2: this.documents.attachment2,
            attachment3: this.documents.attachment3,
            bookingdate: moment(this.receiptdate, "DD/MM/yyyy").format("YYYY-MM-DD"),
            dueamount: this.dueamount,
            include: this.include,
            remainingamount: this.include == 1 ? this.remaining : 0,
            // extraCustomers: extraCustomers
          };
        }

        // var ifEdit = this.$route.params.id ? true : false;
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the Required Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.submitDisable = true;

          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/receipt/${this.$route.params.id}`
              : `${baseApi}/receipt`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.submitDisable = false;

              if (json.data.status == 200 || json.data) {
                this.$swal({
                  title: "Submited",
                  text: json.data.message
                    ? `${json.data.message}`
                    : "Update SuccessFully",
                  icon: "success",
                  timer: 5000,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              }
              this.$router.push("/accounting/receipt");
            })
            .catch((error) => {
              this.submitDisable = false;

              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    removeRow(id) {
      this.extraCustomers.splice(id, 1);
    },
    addcsRow() {
      this.extraCustomers.push({
        customer: "",
        email: "",
        mobile: "",
        aadhar: "",
        pan: "",
        nominee: "",
        nomineerelation: "",
        nomineedob: "",
        nomineephone: "",
      });
    },
    async handleProject() {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getClientProject`,
        data: {
          client_id: this.clientname.id,
        },
      };
      await axios(requestOptions)
        .then((response) => {
          this.projectOptions = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getPlot(id, plotId) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getallplotbyproject/${id}`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.plotOptions = response.data.data;
          if (plotId !== undefined) {
            this.plotOptions.map((item) => {
              if (plotId == item.id) {
                this.plot = item;
              }
            });
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    async init() {
      this.getCompany();
      this.getCustomer();
      this.getLedger();
    },
    async getCustomer() {
      const userData = JSON.parse(localStorage.getItem("userData"));
      const id = userData.id;
      // if (userRole == "admin") {
      //   this.getAllCustomer(id);
      // } else {
      const data = {
        assignto: id,
      };
      const requestoption = {
        method: "POST",
        url: `${this.baseApi}/getcustomerassignby`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: JSON.stringify(data),
      };
      await axios(requestoption).then((response) => {
        this.customerOption = response.data.data;
        if (id !== undefined) {
          this.customerOption.map((item) => {
            if (item.id == id) {
              this.customer = item;
            }
          });
        }
      });
      // }
    },
    // async getBookingNo() {
    //   const requestOptions = {
    //     method: "GET",
    //     headers: {
    //       "Content-type": "application/json",
    //       Authorization: `Bearer ${this.accessToken}`,
    //     },
    //     url: `${this.baseApi}/salesdata`,
    //   };
    //   await axios(requestOptions)
    //     .then((response) => {
    //       this.bookingOptions = response.data.data;
    //     })
    //     .catch((error) => console.log(error, "error"));
    // },
    // handleSales(id) {
    //   if (id !== undefined) {
    //     this.bookingOptions.map((item) => {
    //       if (item.id == id) {
    //         this.sales_id = item;
    //       }
    //     });
    //   }
    //   this.totalamount = this.sales_id.totalpayment;
    //   this.project = this.sales_id.project[0].projectname;
    //   this.plot = this.sales_id.plotno[0].plotname;
    //   this.dueamount = this.sales_id.remainingpayment;

    //   return this.sales_id;
    // },
    async getCompany() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/company`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.companyOption = response.data.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async handleFileChange(e, name, type) {
      this.flag = true;
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachment`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.documents[name] =
            process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
          this.flag = false;
        })
        .catch((error) => console.log(error, "error"));
    },

    onClickBack() {
      this.$router.push("/accounting/receipt");
    },
    async getLedger(id) {
      this.ledgernameOption = [];
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/all-ledger`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.ledgernameOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
      this.ledgernameOption.map((item) => {
        if (item.id == id) {
          this.ledgername = item.name;
        }
      });
    },
  },
};
</script>
